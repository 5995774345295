import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('latestnewspage', () => ({
    selected: null,
    visible: [],
    updateSelection () {
        this.visible.sort((a, b) => {
            return a.el.getBoundingClientRect()
                .top - b.el.getBoundingClientRect()
                .top;
        });
        if (this.visible[0] && this.visible[0].id != this.selected) {
            this.selected = this.visible[0].id;
        }
    },
    left (id) {
        let idx = this.visible.map(e => e.id)
            .indexOf(id);
        if (idx != -1) {
            this.visible.splice(idx, 1);
        }
        this.updateSelection();
    },
    entered (id) {
        this.visible.push({ id: id, el: this.$refs.latestnews_news.querySelector(`#${id}`) });
        this.updateSelection();
    }
}));
